/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { FiMapPin } from "react-icons/fi";
import { GlobalDataContext } from "../../context/context";

const Map = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px]">
        {
          rpdata?.labels?.general?.titleMap ?
            <h2 className="text-center pb-5">
              {rpdata?.labels?.general?.titleMap}
            </h2>
            :
            <h2 className="text-center pb-5">
              We Cover {rpdata?.dbPrincipal?.miles} Miles Around {rpdata?.dbPrincipal?.location?.[0].address}
            </h2>
        }
        {
          rpdata?.dbPrincipal?.location.length > 2 ?
            <div className="pt-4 pb-4 md:p-0 grid grid-cols-1 lg:grid-cols-3 gap-x-0 gap-y-10 lg:gap-x-5">
              {rpdata?.dbPrincipal?.location?.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="py-2 px-3 flex items-center justify-center">
                      <FiMapPin fontSize={25} />
                      <span className="pl-2 font-bold text-[20px]">{item.address}</span>
                    </div>
                    <iframe src={item.url} className="w-full h-[500px]" />
                  </div>
                );
              })}
            </div>
            : null
        }
      </div>
    </div>
  )
}

export default Map
