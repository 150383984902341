import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import GalleryContent from "./GalleryContent";



function GalleryDetail() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    // console.log(id);

    const imgHeader = [
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fstock%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T22%3A53%3A19.382Z-0.jpg?alt=media&token=fb0c5086-5607-4ce4-a558-e6c6c0ad1832',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fstock%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T22%3A53%3A19.382Z-1.jpg?alt=media&token=558f3324-195d-4a45-89c6-c149d7c2a7c1',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fgallery%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T20%3A20%3A24.115Z-24.jpg?alt=media&token=4af68e76-7dc1-4ea4-80d7-72bbe2b48a43',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fgallery%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T20%3A20%3A24.119Z-63.jpg?alt=media&token=8bf1b675-e067-44cf-9d2b-05ba6f9166e0',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fstock%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T22%3A53%3A19.382Z-2.webp?alt=media&token=c7e4c378-fed4-47dd-946f-6aa94cc47e01',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fgallery%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T23%3A15%3A28.799Z-5.jpg?alt=media&token=262883dd-94c3-4754-a3d7-2e08b0bf09eb',
    ]

    return (
        <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
            {
                rpdata?.landings?.map((item, index) => {
                    if (item.name.replace(" ", "-").toLowerCase() === id) {
                        return (
                            <div key={index}>
                                <TransparentHeader
                                    // imagen aleatoria
                                    // bgimg={
                                    //     rpdata?.gallery?.length > 1 ?
                                    //         rpdata?.gallery[Math.floor(Math.random() * rpdata?.gallery?.length)]
                                    //         : rpdata?.stock[Math.floor(Math.random() * rpdata?.stock?.length)]
                                    // }
                                    bgimg={imgHeader[index]}
                                    headertitle={item.name}
                                    Subheader={'Our Projects'}
                                />
                                <>
                                    <div className="w-[90%] mx-auto text-center pt-[100px] pb-[80px]">
                                        <h2 className="pb-5 ">our recent projects of:</h2>
                                        <h2>{item.name}</h2>
                                    </div>
                                    <div className="pb-[100px]">
                                        <GalleryContent galleryImages={item.gallery} />
                                    </div>
                                </>
                            </div>
                        );
                    } else return console.log('Servicio no encontrado')

                })
            }


        </BaseLayout>

    )
}

export default GalleryDetail;

