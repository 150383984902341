import React, { useContext } from "react";
import { FaCaretRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";
import BaseLayout from "../global/BaseLayout";
import TransparentHeader from "../global/TransparentHeader";
import { ButtonContent } from '../global/boton/ButtonContent'
import { BiPhoneCall, BiCalendar } from 'react-icons/bi'
import { MdOutlineEmail } from 'react-icons/md'
import { FaRegClock } from 'react-icons/fa'
import SocialMedia from "../global/SocialMedia";
import CounterUp from '../global/CounterUp'
import Values from "../About/Values";
import Map from "../Contact/MapContent";

function ServicesDetail() {
    const { rpdata } = useContext(GlobalDataContext);
    const { id } = useParams();
    // console.log(id);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    // imagen del header de cada landing pages
    const imgHeader = [
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fstock%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T22%3A48%3A45.560Z-1.jpg?alt=media&token=348efd25-5da9-429f-9c59-b06ae3595bb1',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fstock%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T22%3A48%3A45.560Z-2.jpg?alt=media&token=80b64c59-3f99-48d9-9f39-4968c1cb2a41',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fstock%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T22%3A48%3A45.560Z-3.jpg?alt=media&token=3180e132-ffca-4e62-99de-04213510c157',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fstock%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T20%3A41%3A52.625Z-0.jpg?alt=media&token=c4d04f01-f493-45f0-8789-d852d9b9f685',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fgallery%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T20%3A20%3A24.120Z-71.jpg?alt=media&token=2c721bba-5905-4c5c-b0da-7b8a474a7e75',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fgallery%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T20%3A20%3A24.116Z-29.jpg?alt=media&token=95433186-bdba-4f3a-ab7c-143e47ca7344',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fgallery%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T20%3A20%3A24.121Z-80.jpg?alt=media&token=ef485b15-6188-4c05-b7c1-405af8665fe1',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fgallery%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T20%3A20%3A24.116Z-35.jpg?alt=media&token=c42d0870-af8e-4d6a-bc28-23de9eb35dc4',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fgallery%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T20%3A20%3A24.115Z-24.jpg?alt=media&token=4af68e76-7dc1-4ea4-80d7-72bbe2b48a43',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fgallery%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T20%3A20%3A24.117Z-42.jpg?alt=media&token=d67e893e-2d17-46d0-aba7-8f23092e1807',
        'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F637e8089c0a375a004a1e2cf%2Fstock%2FC%26S%20Home%20Remodeling%20LLC-2022-11-23T22%3A48%3A45.559Z-0.jpg?alt=media&token=b1b88405-7c79-43e8-a83a-ecc9e1f44195',
    ]

    return (
        <BaseLayout PageName={`${rpdata?.dbPrincipal?.name}`}>
            {
                rpdata?.dbServices?.map((item, index) => {
                    if (item.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase() === id) {
                        return (
                            <div key={index}>
                                <TransparentHeader
                                    // imagen aleatoria
                                    // bgimg={
                                    //     rpdata?.gallery?.length > 1 ?
                                    //         rpdata?.gallery[Math.floor(Math.random() * rpdata?.gallery?.length)]
                                    //         : rpdata?.stock[Math.floor(Math.random() * rpdata?.stock?.length)]
                                    // }

                                    bgimg={imgHeader[index]}
                                    headertitle={item.name}
                                    Subheader={'Our Services'}
                                />
                                <div className="w-[95%] md:w-[90%] mx-auto my-10 block md:flex md:gap-5">
                                    <div className="w-full md:w-9/12 shadow-lg shadow-neutral-800/30 p-5 md:p-10 mb-5">
                                        <img
                                            src={item.description[0].img}
                                            alt='no found'
                                            className="w-full object-cover h-auto md:h-[500px]"
                                        />
                                        <h3>
                                            {
                                                rpdata?.dbSlogan[Math.floor(Math.random() * rpdata?.dbSlogan?.length)].slogan
                                            }
                                        </h3>
                                        {
                                            item.description.map((item, index) => {
                                                return (
                                                    <p key={index}>{item.text} <br /> <br /></p>

                                                )
                                            })
                                        }
                                        <ButtonContent />
                                    </div>
                                    <div className="w-full md:w-1/4 shadow-lg bgBloqueServicesDetail p-5 md:p-5 mb-5 text-white">
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start"> Our Services</h4>
                                            <ul className="px-1 md:px-4">
                                                {
                                                    rpdata?.dbServices?.map((nam, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Link
                                                                    to={`/${nam.name.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}`}
                                                                    onClick={goToTop}
                                                                    className='flex items-center text-white pb-3'
                                                                >
                                                                    <FaCaretRight />
                                                                    <span className="pl-2">
                                                                        {nam.name}
                                                                    </span>
                                                                </Link>
                                                            </li>

                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start">Contact</h4>
                                            <ul className="px-1 md:px-4">
                                                {
                                                    rpdata?.dbPrincipal?.phones.map((phone, index) => {
                                                        return (
                                                            <li key={index} className='flex items-center text-white pb-3'>
                                                                <a
                                                                    href={`tel:+1${phone.phone}`}
                                                                    className='flex items-center'
                                                                >
                                                                    <BiPhoneCall />
                                                                    <span className="pl-2">
                                                                        {phone.phone}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                                {
                                                    rpdata?.dbPrincipal?.emails.map((email, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className='flex items-center text-white pb-3'
                                                            >
                                                                <a
                                                                    href={`mailto:${email.email}`}
                                                                    className='flex items-center'
                                                                >
                                                                    <MdOutlineEmail />
                                                                    <span className="pl-2">
                                                                        {email.email}
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="pb-5">
                                            <h4 className="text-center md:text-start">Opening Hours</h4>
                                            <ul className="px-1 md:px-4">
                                                <li
                                                    key={index}
                                                    className='flex items-center text-white pb-3'
                                                >
                                                    <BiCalendar />
                                                    <span className="pl-2">
                                                        {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                                    </span>
                                                </li>
                                                <li
                                                    key={index}
                                                    className='flex items-center text-white pb-3'
                                                >
                                                    <FaRegClock />
                                                    <span className="pl-2">
                                                        {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        {
                                            rpdata?.dbSocialMedia?.redes?.length > 1 ?
                                                <div className="pb-5 text-center md:text-start">
                                                    <h4 >Follow Us</h4>
                                                    <SocialMedia />
                                                </div>
                                                : null
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    } else return console.log('Servicio no encontrado')

                })
            }

            <CounterUp />

            <div className="mt-[-110px]">
                <Values />
            </div>
            <Map />

        </BaseLayout>
    );
}

export default ServicesDetail;